module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"dylanoleary.dev","short_name":"dylanoleary.dev","description":"A place to share my work and thoughts on all things web development","lang":"en","start_url":"/","background_color":"#49ACA0","theme_color":"#49ACA0","display":"minimal-ui","icon":"src/assets/images/devfavicon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-163988368-1","head":true},
    }]
